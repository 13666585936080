.children-wrapper p {
    font-size: 0.9rem;
}

.servicio-wrapper {
    height: 100vh !important;
    max-height: 100vh !important;
    overflow: hidden !important;
    /* padding-top: 45px; */
    padding-top: 60px;
    padding-left: 9vw;
    padding-right: 9vw;
}
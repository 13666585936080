.mainbar, .navbar-ligh{
    background-color: white !important;
    position: fixed !important;
    width: 100vw;
    z-index: 999;
}
.mainbar .nav-link{
    color: #737373;
    font-size: 1.1rem;
    font-weight: 700;
    padding-right: 3vw !important;
    padding-left: 3vw !important;
}

.mainbar .nav-link:hover{
    color: #0a68368a !important;
}
.seguridad-img{
    background-image: url('../assets//seguridad.png');
    height: 150vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.gray{
    background-color: #414042;
    color: white;
}

.verdolaga {
    background-color: #0a6836;
    color: white;
}

.verdin {
    background-color: #78ba93;
    color: white;
}
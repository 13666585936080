.bg {
    padding: 15px;
    background-size: contain;
}

/* .bg1 {
    background-image: url('../assets/bg1.png');
}
.bg2 {
    background-image: url('../assets/bg2.png');
}
.bg3 {
    background-image: url('../assets/bg3.png');
}
.bg4 {
    background-image: url('../assets/bg4.png');
}
.bg5 {
    background-image: url('../assets/bg5.png');
} */
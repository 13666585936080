.three-cols{
    padding-left: 2vw !important;
    padding-right: 2vw !important;
}

.three-cols h3 {
    text-align: center;
}
.three-cols p {
    font-size: 1rem;
}

.three-cols .img-wrapper img {
    width: 40%;
}

.img-wrapper{
    text-align: center;
}

.tercia-list li {
    font-size: 1rem;
    line-height: 1.5rem;
}

.img-wrapper-vert img {
    max-height: 8rem;
    width: auto !important;
}
.img-wrapper-vert {
    max-height: 8rem;
}
.animation::after{
    -webkit-transition: background 1s;
    -moz-transition: background 1s;
    -o-transition: background 1s;
    transition: background 1s;
}

.animation{
    -webkit-transition: background 2s;
    -moz-transition: background 2s;
    -o-transition: background 2s;
    transition: background 2s;
}

.slider-text{
    position: absolute;
    left: 40px;
    bottom: 10%;
    width: 50%;

}

.slider-text h1{
    font-size: 3rem;
    color: white;
    font-weight: 700;
    text-shadow: #272727 3px 2px;
}

.slider-text .text-wrapper{
    color: white;
    line-height: 1.3rem;
}

.slide1, .slide2, .slide3, .slide4{
    height: 93.7vh;
    background-size: cover;
}


.slide1{
    background-image: url('../assets/slider/slider1.png');
}

.slide2{
    background-image: url('../assets/slider/slider2.png');
}

.slide3{
    background-image: url('../assets/slider/slider3.png');
}

.slide4{
    background-image: url('../assets/slider/slider4.png');
}

/* Miniaturas Credits to https://stackoverflow.com/a/54517017 */

.all-slides {
    display: flex;
    width: 40vw;
    margin: 0 auto;
    padding-top: 50vh;
    perspective: 500px;
    height: 500px;
}
  
.single-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 33%;
    position: absolute;
    transition: 1s ease;
    height: 40vh;
}
  
  /* Left slide*/
.single-slide[data-order="1"] {
    left: 00vw;
    transform: translate(50%) scale(1, 1);
    z-index: 1;
    opacity: 0;
    /* display: none; */
}

/* Middle slide */
.single-slide[data-order="2"] {
    left: 20vw;
    transform: translate(50%) scale(0.8, 0.8);
    z-index: 2;
    /* opacity: 0.7; */
}
  
  /* Right slide*/
.single-slide[data-order="3"] {
    left: 33vw;
    transform: translate(50%) scale(0.8, 0.8);
    z-index: 3;
    /* opacity: 0.7; */
}

  /* Last slide*/
.single-slide[data-order="4"] {
    left: 46vw;
    transform: translate(50%) scale(0.8, 0.8);
    z-index: 4;
    /* opacity: 0.7; */
}
  
.single-slide:nth-child(2) {
    order: 2;
}
  
.single-slide:nth-child(1) {
    order: 1;
}
  
.single-slide:nth-child(3) {
    order: 3;
}

.single-slide:nth-child(4) {
    order: 4;
}
.footer{
    background-color: #414042;
    color: white;
    margin-top: 5rem;
}

.footer h5 {
    font-size: 0.85rem;
}

.footer p {
    font-size: 0.8rem;
    line-height: 2rem;
}

.footer .logo-wrapper{
    width: 30%;
    margin-left: auto;
}
